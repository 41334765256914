import { css } from '@emotion/react'
import { memo } from 'react'
import { proxy } from 'valtio'
import useMedia from '~/hooks/useMedia'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { store } from '~/pages/heineken_template/_private/store'
import { FuiUserAvatar } from '../../heineken_template/components/FuiUserAvatar'
import { Preset_Topbar } from '../../heineken_template/_preset/preset_Topbar'
import { OpkevinLogOutView } from './OpkevinLogOutView'
import { useDaddy960Trial } from './useDaddy960Tutorial'

export const daddy960_store = proxy({ userAvatar: new FuiUserAvatar() })

const TrialToolTip = memo<ReactProps>(function TrialToolTip() {
  return (
    <div
      css={css`
        ${fill_horizontal_all_center};
        user-select: none;
        width: 72px;
        height: 32px;
        border-radius: 4px;
        background: linear-gradient(113deg, #ce7d00, #baa401);
      `}
    >
      試用中
    </div>
  )
})

export const Daddy960_TopBar = memo<ReactProps<{ leftBurger?: boolean; rightBerger?: boolean }>>(
  function Daddy960_TopBar(props) {
    const trial = useDaddy960Trial('goodway')
    const { isPc } = useMedia()
    return (
      <div
        css={css`
          ${fill_horizontal_cross_center};
          justify-content: end;
          background: linear-gradient(359deg, #25262b, #15161e);
        `}
      >
        {isPc && (
          <div
            css={css`
              ${fill_horizontal_all_center};
              justify-content: end;
              padding: 0px 16px;
            `}
          >
            {trial.state.subscriptionsState === 'trial' && <TrialToolTip />}
            <ChartingServerSwitch charting={store.charting} />
            <daddy960_store.userAvatar.AvatarModal
              cssset={css`
                width: 280px;
                height: 360px;
                transition: 0.3s;
              `}
            >
              <OpkevinLogOutView />
            </daddy960_store.userAvatar.AvatarModal>
          </div>
        )}

        {!isPc && (
          <Preset_Topbar
            showRightBurger={props.rightBerger ?? true}
            showLeftBurger={props.leftBurger ?? false}
            componentsInRight={
              <div
                css={css`
                  ${fill_horizontal_all_center};
                  justify-content: end;
                `}
              >
                {trial.state.subscriptionsState === 'trial' && <TrialToolTip />}
                <ChartingServerSwitch charting={store.charting} />
                <daddy960_store.userAvatar.AvatarModal
                  cssset={css`
                    width: 280px;
                    height: 360px;
                  `}
                >
                  <OpkevinLogOutView />
                </daddy960_store.userAvatar.AvatarModal>
              </div>
            }
          ></Preset_Topbar>
        )}
      </div>
    )
  },
)
